// TOUCH DEVICES

@media (min-width: 1900px) {



  // IMAGES SIZES //

  .home-gallery{

    .landscape{
      height: 560px;
      max-height: 560px;
      width: auto;
      img{
        height: 100%;
        width: auto;
      }
    }

    .portrait{
      height: calc(778px + 24px);
      max-height: calc(778px + 24px);
      width: auto;
      img{
        height: calc(100% - 24px);
        width: auto;
      }
    }

    .square{
      height: 680px;
      max-height: 680px;
      width: auto;
      img{
        height: 100%;
        width: auto;
      }
    }
  }


  .home-gallery .gallery-item {
    position: relative;

    .gallery-item-legend{
      margin-top: 3px;
      position: absolute;
      left: 0;
    }
  }



  .subpage-content-image{
    padding-top: 8px;
    img.portrait{
      height: 778px;
      width: auto;
    }
    img.landscape{
      height: 560px;
      width: auto;
    }
    .legend{
    }
  }

  ////// SUB SUBPAGE

  .publication-cover {
    padding-top: 8px;
    &.landscape {
      height: 560px;
      width: auto;
      max-width: auto;
      img {
        height: 100%;
        width: auto;
      }
    }

    &.portrait {
      height: 778px;
      max-width: auto;
      width: auto;
      img {
        height: 100%;
        width: auto;
      }
    }

    &.square {
      height: 680px;
      max-width: auto;
      width: auto;
      img {
        width: auto;
        height: 680px;
      }
    }
  }

  // PUBLICATION

  .publication-images{
    .image.portrait{
      img{
        height: 778px;
        max-width: auto;
        width: auto;
      }
    }

    .image.landscape{
      img{
        height: 560px;
        width: auto;
        max-width: auto;
      }
    }

    .image.square{
      img{
        height: 680px;
        max-width: auto;
        width: auto;
      }
    }
  }

  //// EVENT

  .event{
    .publication-images{
      padding-bottom: 0px;
    }
  }

  ////// ÉQUIPE

  .team-member {
    &:first-of-type{
      padding: 0px 28px-sm 13px;
    }
    padding: 9px 28px-sm 13px;
    img {
      height: 778px;
      max-width: auto;
      width: auto;
    }
  }




  .partner{
    .partner-image.landscape{
      img{
        height: 560px;
        width: auto;
        max-width: auto;
      }
    }
    .partner-image.portrait{
      img{
        height: 778px;
        max-width: auto;
        width: auto;
      }
    }
  }

  .image-wrap.square{
    img {
      height: 680px;
      max-width: auto;
      width: auto;
    }
  }

  .image-wrap.portrait{
    img {
      height: 778px;
      max-width: auto;
      width: auto;
    }
  }

  .image-wrap.landscape{
    img {
      height: 560px;
      width: auto;
      max-width: auto;
    }
  }

  .subpage-content-image.portrait{
    img{
      height: 778px;
      max-width: auto;
      width: auto;
    }
  }

  .subpage-content-image.square{
    img{
      height: 680px;
      max-width: auto;
      width: auto;
    }
  }

  .subpage-content-image.landscape{
    img{
      height: 560px;
      width: auto;
      max-width: auto;
    }
  }

  .portrait img {
    height: 778px;
    max-width: auto;
    width: auto;
  }

  .landscape img {
    height: 560px;
    width: auto;
    max-width: auto;
  }

  .square img{
    height: 680px;
    max-width: auto;
    width: auto;
  }










.navbar {
  padding: 0;
  height: 55px;
  padding-bottom: 3px;
}

main {
  padding: 55px 0 0;
}


  // MENUPAGE

.submenu{
  border-bottom: 1px solid;
  padding: calc(28px / 2.5) 28px 17px 28px;
  display: flex;
  flex-wrap: wrap;
}

  .submenu.tags-menu{
    padding: 0 28px calc(28px / 2.7) 28px;
    .tag{
      margin-top: calc(28px / 2.7);
    }
  }

.submenu:first-of-type{
  p{
    max-width: calc(1235px - 36px);
  }
}

.submenu.nw{
  flex-wrap: nowrap;
}


.menu-page.submenu{
  display: block;
}

.more{
  position: relative;
}


.submenu a.ajax-link{
  margin-right: 17px;
}

.submenu .arrow a.ajax-link{
  margin-right: 0px;
}

.submenu .arrow{
  margin-right: 17px;
}

.filter{
  margin-right: 17px;
}

.subpage-content{
  padding: 14px 28px 24px;
  p:first-of-type{
    text-indent: 0px;
    max-width: 1235px;
  }
  p{
    text-indent: 28px;
    max-width: 1235px;
  }
  &.has-socials{
    padding-bottom: 62px;
  }
}

.subpage-content.publications{
  padding-bottom: 14px;
}

.subpage-content.cirvaespacepublic{
  padding-bottom: 14px;
  .subpage-content-header{
    padding-bottom:0px;
  }
}

.subpage-content-header{
  padding-bottom: 5px;
  max-width: 1235px;
}

.subpage-content-grid{
  width: calc(100% + 5px);
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid;
  margin-left: -1px;
  margin-right: -1px;
}

.grid-item{
  margin-bottom: -1px;
  width: 33.3333%;
  padding: 28px 28px 28px - 5px 28px;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  margin-right: -0.5px;
  margin-left: -0.5px;
  border-left: 1px solid;
  border-collapse: collapse;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-collapse: collapse;
}

.single-child{
  .grid-item{
  }
  border-bottom: none;
}

.legend{
  padding-top: 5px;
}

.grid-item.portrait{
  position: relative;
  .more{
    position: absolute;
    right: 28px;
  }
}

.landscape, .square{
  flex-direction: column;
  .more{
    align-self: flex-end;
    order: 3;
    flex: 1;
    align-items: flex-end;
    position: relative;
    margin-top: 25px;
    display: flex;
    a{
      position: absolute;
      top: auto !important;
      bottom: 0 !important;
      left: 0;
      width: 78px !important;
      height: 78px !important;

    }
  }
}

.more{
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  position: relative;

  align-self: flex-start;
  &:before{
    font-size: 78px;
    line-height: 78px;
  }
}

.menu-page-socials{
  position: absolute;
  bottom: 0px;
  padding-top: 15px;
  padding-bottom: 21px;
}

.socials-icons{
  position: relative;
  top: 2px;
  left:-4px;
  span:first-of-type{
    position: relative;
    left:5px;
  }
  span:nth-of-type(2), span:nth-of-type(3){
    position: relative;
    top: 1px;
  }
  span:nth-of-type(4){
    position: relative;
    top:2px;
  }
  a{
    box-shadow: none !important;
  }
}



////// SUB SUBPAGE


.grid-item{
  .legend{
    width: 100%;
  }
}

.subpage-content{
  .legend{
    max-width: 1235px;
  }
}

.publication-legend{
  max-width: 1235px;
}

.publication-cover{
  padding-top: 14px;
}

.grayscale{
  filter: grayscale(100);
}

.publication-text{
  max-width: 1235px;
  padding-top: 1em;
  a:not(.toggle-expand){
    box-shadow: inset 0 -1px 0 #000 !important;
    padding-bottom: 0.1em !important;
  }
}

.publication-images{
  max-width: 1235px;
  padding-top: 1em;
  padding-bottom: 14px;
  .image{
    margin-bottom: 1em;
    &:last-of-type{
      margin-bottom: 0px;
    }
  }
  img{
    max-width: 100%;
  }
  .legend{
    margin-bottom: 16px;
    &:last-of-type{
      margin-bottom: 0px;
    }
  }
}

.empty-publication {
  min-height: 400px;
  justify-content: space-between;
  .grid-item-content{
    flex: 1;
  }
  .icon-more{
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    a{
      display: inline-block;
    }
  }
}

// PUBLICATION

.subpage-content.publication{
  .publication-infos{
    margin-top: 1em;
  }
  .publication-text{
    margin-bottom: 0;
  }
}

.subpage-content.publications{
  .subpage-content-header{
    padding-bottom: 0px;
  }
}




// EVENT

.event{
  .publication-images{
    padding-bottom: 0px;
  }
}

////// ÉQUIPE ///////

.team-member-wrap{
  margin-left: - 28px;
  margin-right: - 28px;
  margin-bottom: -14px;
}

.team-member{
  border-bottom: 1px solid;
  padding: 19px 28px 24px;
  &:first-of-type{
    padding: 0 28px 24px;
  }
  &:last-of-type{
    border-bottom: 0px;
    padding: 19px 28px 14px;
  }
  .legend{
    height: 23px;
  }
}

.toggle-expand {
  width: 22px;
  display: inline-block;
  margin-right: 14px;
  position: relative;
  z-index: 1;
  span{
    &:before{
      content: "+";
      position: relative;
      top: 2px;
    }
  }
  &:hover{
    padding-bottom: 0 !important;
  }
}

.team-member-position{
  margin-left: 36px;
  text-indent: 0px !important;
}

.team-member-image{
  padding-top: 15px;
}

.team-member-bio{
  max-width: 1235px;
  .collapse-text{
    margin-top: 21px;
    margin-bottom: 0em;
  }
}


.toggle-expand.expanded{
  span{
    &:before{
      content:"–";
      top: 0px;
    }
  }
}

// GOUVERNANCE

.subpage-content-header, .publication-text{
  ul{
    li{
      margin-left: 40px;
      position: relative;
      left: -4px;
      &:before {
        content: "—";
        position: relative;
        left: 7px;
        margin-right: 23px;
        margin-left: -40px;
      }
    }
    &:last-of-type{
      margin-bottom: 0px;
    }
  }
}

////// PARTNERIATS ///////

.partners-wrap{
  margin-left: - 28px;
  margin-right: - 28px;
  margin-bottom: -14px;
}

.partner{
  border-bottom: 1px solid;
  padding: 14px 28px 18px;
  &:first-of-type{
    padding: 0px 28px 18px;
  }
  &:last-of-type{
    border-bottom: 0px;
    padding: 19px 28px 11px;
  }
  .legend{
    height: 28px;
  }
}
.partner-name{
  display: flex;
}
.partner-adress{
  margin-left: 36px;
}

.partner-image{
  padding-top: 10px;
}

.partner-text{
  max-width: 1235px;
  .collapse-text{
    margin-top: 16px;
    margin-bottom: 0.2em;
  }
}

// VIDEO


.video-item{
  &:first-of-type{
    margin: 14px 0 0 0;
  }
  margin: 24px 0 0 0;

  &:last-of-type{
    margin: 24px 0 0 0;
  }

}

.video-legend{
  padding-top: 0px;
  margin-bottom: 24px;
  &:last-of-type{
    margin-bottom: 0px;
  }
}

// AMIS DU CIRVA

.amisducirva a{
  box-shadow: inset 0 -1px 0 #000 !important;
  padding-bottom: 0.02em !important;
}

.subpage-content-image{
  padding-top: 14px;
}

// MENTIONS LÉGALES

.copyrights{
  margin-bottom: 1em;
}

//EXHIBITION

.exhibition .publication-cover{
  padding-top: 17px;
}

// ARTIST

.artist-content .publication-cover{
  padding-top: 17px;
}


// ONLINE COLLETION

.online-collection-wrap{
  height: calc(100% - 45px) !important;
  padding: 0 !important;
  /*  padding-top: 25px !important;*/
  iframe{
    border: none;
  }
}

.online-collection-content{
  height: 100%;
  width: 100%;
}

#onlinecollection{
  width: 100%;
  height: calc(100%);
}




// VISITER LE CIRVA

.informations p+ul{
  margin-top: 0 !important;
  margin-bottom: 0!important;
}

.collapse-item-text{
  padding-bottom: 1em;
  p{
    text-indent: 0px;
  }
}

// NEWSLETTER

#sib-form{
  max-width: 1235px;
}

#success-message{
  display: none;
}

#error-message{
  display: none;
}

.form__label-row{
  margin-bottom: 28px;
}

.archives{
  p:last-of-type{
    margin-bottom: 1em !important;
  }
}

.sib-form .clickable__icon {
  height: 1.5rem;
  vertical-align: bottom;
  width: 1.5rem;
}

.entry__error{
  position: absolute;
  bottom:28px;
}

.clickable__icon{
  width: 0px !important;
  height: 0px !important;
}

.sib-form-block__button{
  border: 1px solid #000;
  background-color: transparent;
  padding-bottom: 0.2em;
  margin-top: 28px;
  color: black !important;
  padding-left: 0.2em;
  padding-right: 0.2em;
  svg{
    display: none;
  }
}

.sib-form-block__button:hover{
  cursor: pointer;
}

label{
  margin-bottom: 0px;
}

.input{
  border-radius: 0px !important;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid;
  padding: 0;
  width: 548px;
}

.options-list{
  margin-top: 10px;
}


.container {
  display: block;
  position: relative;
  vertical-align: middle;
  padding-left: 25px;
  max-width: 100% !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid;
  background-color: none;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #FFF;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #FFF;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid black;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


// ON GOING RESIDENCIES

.artist-cover-image{
  width: auto;
  position: absolute;
  right: 28px;
  top: 71px;
  &.bw{
    filter: grayscale(1);
  }
}

.exhibition{
  .publication-images{
    padding-bottom: 0px;
    padding-top: 1.35em;
  }
}

// PAST RESIDENCIES



.intro-text{
  margin-bottom: 1em;
  a{
    box-shadow: inset 0 -1px 0 #000 !important;
    padding-bottom: 0.02em !important;
  }
}

.artists-list{
  margin-bottom: 0px;
}

.past .artist-list-item{

  span{
    display: inline-block;
    width: 20px;
  }
  span+a{
    margin-left: 5px;
  }
  a{
    margin-left: 30px;
  }
}

.container-wrap.header-artist{
  display: flex;
  flex-wrap: wrap;

  .artist-bio{
    width: 100%;
    p{
      max-width: 1235px;
    }
  }
}

.publication-cover-wrap{
  margin-right: 28px;
}

.artist-content{
  .container-wrap {
    border-top: 1px solid;
    margin-left: - 28px;
    margin-right: - 28px;
    padding-right: 28px;
    padding-left: 28px;
    padding-bottom: 28px - 5px;
    &:first-of-type{
      border-top: 0px;
    }
  }

  .image-wrap{
    margin-top: 28px;
  }

  .video-wrap{
    margin-top: 28px;
  }

  .collapse-item-text.ni{
    p:last-of-type{
      margin-bottom: 1em !important;
    }
  }
}

.container-wrap.artist-gallery{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .icon-more{
    align-self: flex-start;
    position: relative;
  }
  .more{
    flex:0;
    margin-top: 28px;
  }
}

.artist-content .container-wrap:last-of-type{
  padding-bottom: 0px;
}

.artist-content .publication-text{
  padding-top: 14px;
}

.icon-more{
  a{
    position: absolute;
    top:0;
    left:0;
    width: 78px;
    height: 78px;
  }
}

.artist-image{
  padding-top: 1em !important;
  &:first-of-type{
    padding-top: 14px !important;
  }
  &:last-of-type{
    margin-bottom: 14px;
  }
}

}






