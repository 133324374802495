:root {
  @each $size, $value in $margin {
    --margin-#{$size}: calc(var(--base-space) * #{$value});
  }

  @each $size, $item in $fontsize {
    @each $screen, $values in $item {
      @include mq($screen) {
        @each $type, $value in $values {
          --#{$type}-#{$size}: #{$value};
        }
      }
    }
  }
}
