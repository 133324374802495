// TYPOGRAPHY

@font-face {
  font-family: 'GreyLL-Book';
  src: url('../fonts/GreyLL-Book.woff2') format('woff2'),
  url('../fonts/GreyLL-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GreyLL-BookItalic';
  src: url('../fonts/GreyLL-BookItalic.woff2') format('woff2'),
  url('../fonts/GreyLL-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


// ====== FONTS FAMILIES ====== //


body{
  font-size: 19px;
  line-height: 25px;
  color: black !important;
  font-family: "GreyLL-Book", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

sup{
  font-feature-settings: 'sups';
}

.small-txt{
  font-size: 14.8px;
  line-height: 18px;
}

em{
  font-family: "GreyLL-BookItalic";
  font-style: normal !important;
}


@media (max-width: 768px) {

  body {
    font-size: 17px;
    line-height: 21px;
    font-family: "GreyLL-Book";
  }

  .small-txt{
    font-size: 12.75px;
    line-height: 15.75px;
  }
}


// ===== MEDIUM DEVICES ===== //

@media (min-width:769px) and (max-width: 1024px) {

  // ===== TITRE ===== //
  $titre-fs: 4.5vw;
  $titre-ln: 4.5vw;

  // ===== TEXTE COURANT ===== //
  $labeur-fs: 0.4vw;
  $labeur-ln: 2.2vw;
  $labeur-ti: 2.7777777777777777vw;

  // ===== NOTES ===== //
  $note-fs: 0.9027777777777777vw;
  $note-ln: 1.1805555555555556vw;
  $note-ti: 1.0416666666666665vw;


}

// ===== LARGE DEVICES ===== //

@media (min-width:1920px){

  body {
    font-size: 22px;
    line-height: 28px;
    font-family: "GreyLL-Book";
  }

  .small-txt{
    font-size: 17.14px;
    line-height: 20.1px;
  }

}