// TOUCH DEVICES

@media (max-device-width: 600px) {
  
  .home-gallery{
    
    .landscape{
      width: 100%;
      max-width: 100%;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }

    .portrait{
      width: 78%;
      max-width: 78%;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }

    .square{
      width: 80%;
      max-width: 80%;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }
  }


  .subpage-content-image{
    padding-top: 8px;
    img.portrait{
      width: 78%;
      height: auto;
    }
    img.landscape{
      width: 100%;
      height: auto;
    }
  }

  ////// SUB SUBPAGE

  .publication-cover {
    padding-top: 8px;
    &.landscape {
      width: 100%;
      max-width: 100%;
      height: auto;
      img {
        width: 100% !important;
        max-width: 100% !important;
        height: auto;
      }
    }

    &.portrait {
      width: 78%;
      max-width: 78%;
      height: auto;
      img {
        width: 100% !important;
        max-width: 100% !important;
        height: auto;
      }
    }

    &.square {
      width: 80%;
      max-width: 80%;
      height: auto;
      img {
        width: 100% !important;
        max-width: 100% !important;
        height: auto;
      }
    }
  }

  .publication-images{
    .image.portrait{
      img{
      width: 78%;
      max-width: 78%;
      height: auto;
      }
    }

    .image.landscape{
      img{
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    .image.square{
      img{
        width: 80%;
        max-width: 80%;
        height: auto;
      }
    }
  }

  ////// ÉQUIPE

  .team-member {
    &:first-of-type{
      padding: 0px $margin-sm 13px;
    }
    padding: 9px $margin-sm 13px;
    img {
      height: auto;
      width: 78%;
    }
  }



////GRID TEMPLATE
  
  .grid-item.portrait img {
    height: auto;
    width: calc(100% - 66px);
    max-width: 78%;
  }


    .landscape .grid-item-content img{
  width: 100%;
  max-width: 100%;
  height: auto;
}

.square .grid-item-content img{
  width: 80%;
  max-width: 80%;
  height: auto;
}
  

  .partner{
    .partner-image.landscape{
      img{
        width: 100%;
        height: auto;
      }
    }
    .partner-image.portrait{
      img{
        width: 78%;
        height: auto;
      }
    }
  }


  .image-wrap.square{
    img {
      width: 80%;
      max-width: 80%;
      height: auto;
    }
  }

  .image-wrap.portrait{
    img {
      width: 78%;
      max-width: 78%;
      height: auto;
    }
  }

  .image-wrap.landscape{
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .subpage-content-image.portrait{
    img{
      width: 78%;
      max-width: 78%;
      height: auto;
    }
  }

  .subpage-content-image.square{
    img{
      width: 80%;
      max-width: 80%;
      height: auto;
    }
  }

  .subpage-content-image.landscape{
    img{
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .portrait img {
    width: 78%;
    max-width: 78%;
    height: auto;
  }

  .landscape img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .square img{
    width: 80%;
    max-width: 80%;
    height: auto;
  }



  .gallery-wrap{
    .image-wrap.portrait img{
      width: calc(78% + 38px);
      max-width: calc(78% + 38px);
      height: auto;
    }
  }

}

