.submenu.tags-menu{
  display: flex;
  padding-top: 0;
  .tag{
    width: max-content;
    margin-top: 7px;
    margin-right: 0.5em;
  }
  &.in-row{
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}


.subpage-content-wrapper{
  position: relative;
  .event-content-grid{
    border-top: none;
  }
  .event-content-grid{
    display: none;
  }
  .event-content-grid.show{
    opacity: 1;
    display: flex;
  }
}

.event-content-grid .grid-item{
  display: none;
  &.show{
    display: flex;
  }
}
