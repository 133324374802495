.filters-menu{
  border-top: 1px solid;
  a{
    margin-right: 17px;
    text-decoration: none;
  }
}


.submenu.tags-menu{
  border-bottom: none;
  display: block;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.tags{
  border-bottom: 1px solid;
  .tags-collapse-inner{
    padding-left: $margin-sm;
    padding-bottom: 7px;
    @include mq(md){
      padding-left: $margin;
      padding-right: $margin;
    }
  }
}
.tags.collapse.show{
  display: block !important;
}

.tags.collapse{
  @include mq(md){
    display: block;
  }
}

.tag{
  display: inline-block;
  align-items: center;
  justify-content: center;
  background-color: #ffdcf2;
}

.collapse-tag{
  border-bottom: 1px solid;
  padding: 0 $margin-sm;
  @include mq(md){
    display: none;
    padding: 0 $margin;
  }
}

.collapse-tag[aria-expanded="true"] .symbol-tag span{
  &:before{
    content: "–";
    top:0;
  }
}

.symbol-tag{
  display: inline-block;
  position: relative;
  top: -2px;
  margin-right: 0.5em;
}

.symbol-tag span{
  &:before{
    content:"+";
    position: relative;
    top: 2px;
  }
}

.tag-button{
  @extend .tag;
  margin-bottom: 7px;
  @media (hover: hover) {
    &:hover{
      color: black !important;
      background-color: #ffdcf2 !important;
    }
  }
}
