// RESET

*{
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

p,h1,h2,h3,h4,h5,h6{
  margin:0;
}

ul{
  list-style-type: none;
  padding: 0;
}

a{
  color: black;
}

a:hover{
  text-decoration: none !important;
  color: black;
  box-shadow: inset 0 -1px 0 #000 !important;
  padding-bottom: 0.02em !important;
}

.no-hover{
  text-decoration: none !important;
  box-shadow: none !important;
}

.no-hover:hover{
  text-decoration: none !important;
  box-shadow: none !important;
}
.number{
  font-variant-numeric: tabular-nums;
  margin-right: 12px;
}

body,html, .page{
  overflow: hidden;
  position: fixed;
  height:100%;
  width: 100%;
  padding:0;
  margin:0;
}

main{
  padding: $header-height 0 0;
  background-color: $pink;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 0.1s ease-in-out;
}