// COLOR

$pink : #ffdcf2;
$red: #ff6c37;


// SIZE

$header-height: 46px;
$margin: 23px;

$margin-sm: 18px;
$header-height-sm: 41px;




// EFFECTS

.fade-out{
  opacity: 0 !important;
  transition: opacity 0.2s ease-in-out;
}

.fade-in{
  opacity: 1 !important;
  transition: opacity 0.2s ease-in-out;
}

.bkg-pink{
  background-color: $pink !important;
  transition: background-color 0.1s ease-in-out;
}

.bkg-white{
  background-color: white !important;
  transition: background-color 0.1s ease-in-out;
}

.hide{
  display: none !important;
}

.show-flex{
  display: flex !important;
}

.hide-toggle{
  opacity:0;
  pointer-events: none;
  cursor: default;
}

.op-0{
  opacity: 0 !important;
  transition-delay: 0.25s;
}

.op-1-delay{
  opacity: 1 !important;
}

.nb-b{
  border-bottom: none !important;
}

.nb-t{
  border-top: none !important;
}

.nb-r{
  border-right: none !important;
}

.nb-l{
  border-left: none !important;
}

.ni{
  p{
    text-indent: 0px !important;
    margin-bottom: 1em !important;
  }

  p+ul{
    margin-top: -1em;
  }

  ul{
    margin-bottom: 1em !important;
  }

  p:last-of-type{
    margin-bottom: 0 !important;
  }
}

.ti{
  p:first-of-type{
    text-indent: 0 !important;
    margin-bottom: 0 !important;
  }
  p{
    text-indent: $margin !important;
    margin-bottom: 0 !important;
  }
  p:last-of-type{
    margin-bottom: 0 !important;
  }
}

.p-mb p{
  margin-bottom: 1em;
  text-indent: 0px !important;
}


// TEXT

.td-red{
  color: $red !important;
}

.td-undl{
  box-shadow: inset 0 -1px 0 #000 !important;
  padding-bottom: 0.02em !important;
}



.hover-td-red:hover{
  color: $red;
  text-decoration: none;
  box-shadow: none !important;
}

.hover-td-undl:hover{
  box-shadow: inset 0 -1px 0 #000 !important;
  padding-bottom: 0.02em !important;
}

.td-undl-white{
  box-shadow: inset 0 -1px 0 white !important;
  padding-bottom: 0.02em !important;
}

.hover-td-red.hover-td-undl{
  box-shadow: inset 0 -1px 0 $red !important;
  padding-bottom: 0.02em !important;
}

.td-red.hover-td-undl:hover{
  text-decoration: none;
  box-shadow: inset 0 -1px 0 $red !important;
  padding-bottom: 0.02em !important;
}

.td-red.td-undl{
  text-decoration: none;
  box-shadow: inset 0 -1px 0 $red !important;
  padding-bottom: 0.02em !important;
}





.menu-link.hover-td-undl:hover{
  text-decoration: none;
  box-shadow: inset 0 -1px 0 #000 !important;
  padding-bottom: 0em !important;
}

.icon-more{
  a:hover{
    box-shadow: none !important;
  }
}

.disable{
  opacity: 0;
  pointer-events: none;
}

.d-i{
  display: inline !important;
}

.d-ib{
  display: inline-block !important;
}

.d-b{
  display: block !important;
}

