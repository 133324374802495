@font-face {
	font-family: "demo-icons";
	src: url('../fonts/icon-font/demo-icons.eot');
	src: url('../fonts/icon-font/demo-icons.eot?#iefix') format('eot'),
		url('../fonts/icon-font/demo-icons.woff2') format('woff2'),
		url('../fonts/icon-font/demo-icons.woff') format('woff'),
		url('../fonts/icon-font/demo-icons.ttf') format('truetype'),
		url('../fonts/icon-font/demo-icons.svg#demo-icons') format('svg');
}

.icon:before {
	font-family: "demo-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-cross:before {
	content: "\E001";
}

.icon-logo:before {
	content: "\E002";
}

.icon-more:before {
	content: "\E003";
}

.icon-rs-f:before {
	content: "\E004";
}

.icon-rs-inst:before {
	content: "\E005";
}

.icon-rs-tw:before {
	content: "\E006";
}

.icon-rs-vim:before {
	content: "\E007";
}

.icon-z-logo:before {
	content: "\E008";
}
