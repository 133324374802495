.submenu {
  border-bottom: 1px solid;
  padding: $margin/2.5 $margin 12px $margin;
  display: flex;
  flex-wrap: wrap;
}

.submenu.filters-menu {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
  border-top: none;
}

.submenu.tags-menu + .subpage-content-grid {
  border-top: none;
}

.submenu.tags-menu {
  position: sticky;
  z-index: 99;
  top: var(--sticky-top);
  background-color: white;
  padding: 7px $margin 7px $margin;
  align-items: center;
  border-bottom: 1px solid;

  .tag {
    display: inline-block;
    line-height: 0.6em;
    border-radius: 0.3em;
    padding: 0.4em 1em 0.6em;
    cursor: pointer;

    &.active {
      background-color: black;
      color: white;
    }

    @media (hover: hover) {
      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}

  .submenu:first-of-type {
    p {
      max-width: calc(806px - 36px);
    }
  }

  .submenu.nw {
    flex-wrap: nowrap;
  }


  .menu-page.submenu {
    display: block;
  }

  .more {
    position: relative;
  }


  .submenu a.ajax-link {
    margin-right: 17px;
  }

  .submenu .arrow a.ajax-link {
    margin-right: 0px;
  }

  .submenu .arrow {
    margin-right: 17px;
  }

  .filter {
    margin-right: 17px;
  }

  .subpage-content {
    padding: 9px $margin 19px;

    p:first-of-type {
      text-indent: 0px;
      max-width: 806px;
    }

    p {
      text-indent: $margin;
      max-width: 806px;
    }

    &.has-socials {
      padding-bottom: 52px;
    }
  }

  .subpage-content.events {
    padding-bottom: 14px;
    border-bottom: 1px solid;
  }

  .subpage-content.publications {
    padding-bottom: 14px;
  }

  .subpage-content.cirvaespacepublic {
    padding-bottom: 14px;

    .subpage-content-header {
      padding-bottom: 0px;
    }
  }

  .subpage-content-header {
    padding-bottom: 5px;
    max-width: 806px;
  }

  .subpage-content-grid {
    width: calc(100% + 5px);
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid;
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-item {
    margin-bottom: -1px;
    width: 33.3333%;
    padding: $margin $margin $margin - 5px $margin;
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    margin-right: -0.5px;
    margin-left: -0.5px;
    border-left: 1px solid;
    border-collapse: collapse;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-collapse: collapse;
  }

  .single-child {
    .grid-item {
    }

    border-bottom: none;
  }

  .legend {
    padding-top: 5px;
  }

  .grid-item.portrait {
    position: relative;

    .more {
      position: absolute;
      right: 23px;
    }
  }

  .portrait {
    flex-direction: column;

    .grid-item-content {
      width: 100%;
      /*      display: flex;*/
      justify-content: space-between;

      img {
        width: 290px;
        max-width: calc(100% - 93px);
      }
    }
  }

  .landscape, .square {
    flex-direction: column;

    .grid-item-content {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .more {
      align-self: flex-end;
      order: 3;
      flex: 1;
      align-items: flex-end;
      position: relative;
      margin-top: 20px;
      display: flex;

      a {
        position: absolute;
        top: auto !important;
        bottom: 0 !important;
        left: 0;
        width: 70px !important;
        height: 70px !important;

      }
    }
  }

  .grid-item .legend .bullet {
    display: none;
    height: 1em;
    width: 1em;
    background-color: #ffdcf2;
    border-radius: 100%;
    position: relative;
    top: 0.12em;
    margin-right: 0.5em;
  }

  .more {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    position: relative;

    align-self: flex-start;

    &:before {
      font-size: 70px;
      line-height: 70px;
    }
  }

  .menu-page-socials {
    position: absolute;
    bottom: 0px;
    background-color: $pink;
    padding-bottom: 18px;
    padding-top: 12px;
    width: 100%;
  }

  .socials-icons {
    position: relative;
    top: 2px;
    left: -4px;

    span:first-of-type {
      position: relative;
      left: 5px;
    }

    span:nth-of-type(2), span:nth-of-type(3) {
      position: relative;
      top: 1px;
    }

    span:nth-of-type(4) {
      position: relative;
      top: 2px;
    }

    a {
      box-shadow: none !important;
    }
  }


  ////// SUB SUBPAGE

  .portrait {
    img {
      height: 550px;
      width: auto;
    }
  }

  .landscape {
    img {
      height: 400px;
      width: auto;
    }
  }

  .square {
    img {
      height: 450px;
      width: auto;
    }
  }

  .grid-item.portrait {

    img {
      height: auto;
      width: 290px;
      max-width: calc(100% - 93px);
    }
  }

  .grid-item.landscape {

    img {
      height: auto;
      width: 100%;
      max-width: 100%;
    }
  }

  .grid-item.square {

    img {
      height: auto;
      width: 100%;
      max-width: 350px;
    }
  }

  .grid-item {
    .legend {
      width: 100%;
    }
  }

  .subpage-content {
    .legend {
      max-width: 806px;
    }
  }

  .publication-legend {
    max-width: 806px;
  }

  .publication-cover {
    padding-top: 14px;
  }

  .grayscale {
    filter: grayscale(100);
  }

  .publication-text {
    max-width: 806px;
    padding-top: 1em;

    a:not(.toggle-expand) {
      box-shadow: inset 0 -1px 0 #000 !important;
      padding-bottom: 0.1em !important;
    }
  }

  .publication-images {
    max-width: 806px;
    padding-top: 1em;
    padding-bottom: 9px;

    .image {
      margin-bottom: 1em;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    img {
      max-width: 100%;
    }

    .legend {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  .empty-publication {
    min-height: 400px;
    justify-content: space-between;

    .grid-item-content {
      flex: 1;
    }

    .icon-more {
      display: flex;
      align-self: flex-end;
      justify-content: flex-end;

      a {
        display: inline-block;
      }
    }
  }

  // PUBLICATION

  .subpage-content.publication {
    .publication-infos {
      margin-top: 1em;
    }

    .publication-text {
      margin-bottom: 0;
    }
  }

  .subpage-content.publications, .subpage-content.events {
    .subpage-content-header {
      padding-bottom: 0px;
    }
  }


  // EVENT

  .event {
    .publication-images {
      padding-bottom: 0px;
    }
  }

  ////// ÉQUIPE ///////

  .team-member-wrap {
    margin-left: - $margin;
    margin-right: - $margin;
    margin-bottom: -9px;
  }

  .team-member {
    border-bottom: 1px solid;
    padding: 9px $margin 18px;

    &:first-of-type {
      padding: 0 $margin 18px;
    }

    &:last-of-type {
      border-bottom: 0px;
    }

    .legend {
      height: 23px;
    }
  }

  .toggle-expand {
    width: 22px;
    display: inline-block;
    margin-right: 14px;
    position: relative;
    z-index: 1;

    span {
      &:before {
        content: "+";
        position: relative;
        top: 2px;
      }
    }

    &:hover {
      padding-bottom: 0 !important;
    }
  }

  .team-member-position {
    margin-left: 36px;
    text-indent: 0px !important;
  }

  .team-member-image {
    padding-top: 10px;

    img {
      height: 550px;
    }
  }

  .team-member-bio {
    max-width: 806px;

    .collapse-text {
      margin-top: 16px;
      margin-bottom: 0.5em;
    }
  }


  .toggle-expand.expanded {
    span {
      &:before {
        content: "–";
        top: 0px;
      }
    }
  }

  // GOUVERNANCE

  .subpage-content-header, .publication-text {
    ul {
      li {
        margin-left: 40px;
        position: relative;
        left: -4px;

        &:before {
          content: "—";
          position: relative;
          left: 7px;
          margin-right: 23px;
          margin-left: -40px;
        }
      }

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  ////// PARTNERIATS ///////

  .partners-wrap {
    margin-left: - $margin;
    margin-right: - $margin;
    margin-bottom: -9px;
  }

  .partner {
    border-bottom: 1px solid;
    padding: 9px $margin 18px;

    &:first-of-type {
      padding: 0px $margin 18px;
    }

    &:last-of-type {
      border-bottom: 0px;
    }

    .legend {
      height: 23px;
    }
  }

  .partner-name {
    display: flex;
  }

  .partner-adress {
    margin-left: 36px;
  }

  .partner-image {
    padding-top: 10px;
  }

  .partner-text {
    max-width: 806px;

    .collapse-text {
      margin-top: 16px;
      margin-bottom: 0.5em;
    }
  }

  // VIDEO


  .video-item {
    &:first-of-type {
      margin: 14px 0 0 0;
    }

    margin: 19px 0 0 0;

    &:last-of-type {
      margin: 19px 0 0 0;
    }

  }

  .video-legend {
    padding-top: 5px;
    margin-bottom: 18px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  // AMIS DU CIRVA

  .amisducirva a {
    box-shadow: inset 0 -1px 0 #000 !important;
    padding-bottom: 0.02em !important;
  }

  .subpage-content-image {
    padding-top: 14px;

    img.portrait {
      height: 550px;
    }

    img.landscape {
      height: 400px;
    }

    .legend {
    }
  }

  // MENTIONS LÉGALES

  .copyrights {
    margin-bottom: 1em;
  }

  //EXHIBITION

  .exhibition .publication-cover {
    padding-top: 12px;
  }

  // ARTIST

  .artist-content .publication-cover {
    padding-top: 12px;
  }


  // ONLINE COLLETION

  .online-collection-wrap {
    height: calc(100% - 45px) !important;
    padding: 0 !important;
    /*  padding-top: 25px !important;*/
    iframe {
      border: none;
    }
  }

  .online-collection-content {
    height: 100%;
    width: 100%;
  }

  #onlinecollection {
    width: 100%;
    height: calc(100%);
  }


  // VISITER LE CIRVA

  .informations p + ul {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .collapse-item-text {
    padding-bottom: 1em;

    p {
      text-indent: 0px;
    }
  }

  // NEWSLETTER

  #sib-form {
    max-width: 806px;
  }

  #success-message {
    display: none;
  }

  #error-message {
    display: none;
  }

  .form__label-row {
    margin-bottom: $margin;
  }

  .archives {
    p:last-of-type {
      margin-bottom: 1em !important;
    }
  }

  .sib-form .clickable__icon {
    height: 1.5rem;
    vertical-align: bottom;
    width: 1.5rem;
  }

  .entry__error {
    position: absolute;
    bottom: 23px;
  }

  .clickable__icon {
    width: 0px !important;
    height: 0px !important;
  }

  .sib-form-block__button {
    border: 1px solid #000;
    background-color: transparent;
    padding-bottom: 0.3em;
    margin-top: 23px;
    color: black !important;
    padding-left: 0.5em;
    padding-right: 0.5em;

    svg {
      display: none;
    }
  }

  .sib-form-block__button:hover {
    cursor: pointer;
  }

  label {
    margin-bottom: 0px;
  }

  .input {
    border-radius: 0px !important;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid;
    padding: 0;
    width: 548px;
  }

  .options-list {
    margin-top: 10px;
  }


  .container {
    display: block;
    position: relative;
    vertical-align: middle;
    padding-left: 25px;
    max-width: 100% !important;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 7px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid;
    background-color: none;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #FFF;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #FFF;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: solid black;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


  // ON GOING RESIDENCIES

  .artist-cover-image {
    width: auto;
    position: absolute;
    right: 23px;
    top: 71px;

    &.bw {
      filter: grayscale(1);
    }
  }

  .exhibition {
    .publication-images {
      padding-bottom: 0px;
      padding-top: 1.35em;
    }
  }

  // PAST RESIDENCIES


  .intro-text {
    margin-bottom: 1em;

    a {
      box-shadow: inset 0 -1px 0 #000 !important;
      padding-bottom: 0.02em !important;
    }
  }

  .artists-list {
    margin-bottom: 0px;
  }

  .past .artist-list-item {

    span {
      display: inline-block;
      width: 20px;
    }

    span + a {
      margin-left: 5px;
    }

    a {
      margin-left: 30px;
    }
  }

  .container-wrap.header-artist {
    display: flex;
    flex-wrap: wrap;

    .artist-bio {
      width: 100%;

      p {
        max-width: 806px;
      }
    }
  }

  .publication-cover-wrap {
    margin-right: 23px;
  }

  .artist-content {
    .container-wrap {
      border-top: 1px solid;
      margin-left: - $margin;
      margin-right: - $margin;
      padding-right: $margin;
      padding-left: $margin;
      padding-bottom: $margin - 5px;

      &:first-of-type {
        border-top: 0px;
      }
    }

    .image-wrap {
      margin-top: $margin;
    }

    .video-wrap {
      margin-top: $margin;
    }

    .collapse-item-text.ni {
      p:last-of-type {
        margin-bottom: 1em !important;
      }
    }
  }

  .container-wrap.artist-gallery {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .icon-more {
      align-self: flex-start;
      position: relative;
    }

    .more {
      flex: 0;
      margin-top: 23px;
    }
  }

  .artist-content .container-wrap:last-of-type {
    padding-bottom: 0px;
  }

  .artist-content .publication-text {
    padding-top: 14px;
  }

  .icon-more {
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 70px;
      height: 70px;
    }
  }

  .artist-image {
    padding-top: 1em !important;

    &:first-of-type {
      padding-top: 14px !important;
    }

    &:last-of-type {
      margin-bottom: 14px;
    }
  }


  // RADIO

  .radio-item-wrapper {
    width: 100%;
  }

  .subpage-content-grid {
    .subpage-content-header-radio {
      padding: calc(28px / 2.5) 24px 17px 24px;
      border-bottom: 1px solid #000;
      width: 100%;
      max-width: 100%;

      p {
        max-width: 806px;
      }
    }
  }

  .radio-item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid;

    .radio-item-row {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
    }

    .play-pause {
      padding: $margin/2.5 $margin 12px $margin;
      border-right: 1px solid;
      min-width: 113px;
      text-align: center;
      display: flex;
      align-items: center;
    }

    .radio-item-content {
      padding: calc(28px / 2.5) 28px 17px 28px;
      display: flex;
      flex: 1;

      .toggle-expand {
        position: relative;
        top: -1px;
      }

      .radio-item-date {
        margin-right: 14px;
      }

      .radio-item-title {
        display: flex;
        flex: 1;
      }

      .radio-item-length {
        display: flex;
      }
    }


    .radio-collapse {
      border-top: 1px solid #000;
      border-bottom: none !important;
      width: 100%;
    }

    .radio-listener {
      padding: 0 24px;
      display: flex;
      align-items: center;
      height: 54px;
      border-bottom: 1px solid;
    }

    .progress-bar {
      cursor: pointer;
      border: 1px solid #000;
      width: 400px;
      background-color: white;
    }

    .progress {
      background: black;
      transition: width 0.2s;
      width: 0;
      border-radius: 0;
    }

    .radio-collapse {
      width: 100%;
    }

    .radio-collapse-wrap {
      width: 100%;
    }

    .radio-collapse-content {
      padding: calc(28px / 2.5) 24px 17px 24px;
      width: 100%;

      p {
        max-width: 806px;
        width: 100%;
        margin-bottom: 1em;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      a {
        box-shadow: inset 0 -1px 0 #000 !important;
        padding-bottom: 0.02em !important;
      }
    }


    /// AUDIO PLAYER

    button {
      border: 0;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
    }

    .audio-player {
      height: 12px;
      width: 100%;

      font-family: arial;
      color: white;
      font-size: 0.75em;
      overflow: hidden;

      display: grid;
      grid-template-rows: 6px auto;

      .timeline {
        height: 12px;
        background: white;
        border: 1px solid #000;
        width: 450px;
        position: relative;
        cursor: pointer;

        .progress {
          background: black;
          width: 0%;
          height: 100%;
          transition: 0.25s;
        }
      }

      .controls {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 0 20px;

        > * {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .time {
          display: flex;

          > * {
            padding: 2px;
          }
        }

        .volume-container {
          cursor: pointer;

          .volume-button {
            height: 26px;
            display: flex;
            align-items: center;

            .volume {
              transform: scale(0.7);
            }
          }

          position: relative;
          z-index: 2;

          .volume-slider {
            position: absolute;
            left: -3px;
            top: 15px;
            z-index: -1;
            width: 0;
            height: 15px;
            background: white;
            box-shadow: 0 0 20px #000a;
            transition: .25s;

            .volume-percentage {
              background: coral;
              height: 100%;
              width: 75%;
            }
          }

          &:hover {
            .volume-slider {
              left: -123px;
              width: 120px;
            }
          }
        }
      }
    }


  }


