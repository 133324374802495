.navbar {
  padding: 0;
  height: $header-height;
  padding-bottom: 3px;
}

.navbar-toggler {
  border: none;
  padding: 0;
  color: black !important;
  outline: none !important;
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
  span {
    &:after {
      content: "–";
      position: relative;
      top:-2px;
    }
  }

  &.collapsed {
    span {
      &:after {
        content: "+";
        top: 0px;
      }
    }
  }
}

.mobile-menu {
  display: none;
}
.nav-link{
  padding: 0 !important;
}
.nav-link.menu-link{
  padding-left: 0 !important;
  padding-right: 0px !important;
  margin-right: 17px;
  color: black !important;
}

.navbar-brand {
  font-size: 1em;
  display: none;
  padding: 0;
}

.nav-page-title{
  display: none;
}

.language-menu li{
  display: inline-block;
}

.language{
  a{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.lang-fr{
  &:before{
    content: "Fr";
  }
}

.lang-en{
  &:before{
    content: "En";
  }
}

.bar{
  position: relative;
  top: -1px;
}



.logo {
  margin-right: 0;
  &:before {
    content: "\E008";
    font-size: 26px;
    line-height: $header-height;
    color: $red;
  }
}

.mobile-menu{
  .return-home{
    position: relative;
    top: auto;
    right: auto;
  }
}

.return-home{
  padding: 0 !important;
  position: relative;
  top: 1px;
  right: -4px;
  &:hover{
    padding: 0 !important;
  }
}

body div[data-template='onlinecollection'] .submenu{
  background-color: white !important;
}

