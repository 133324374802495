// MD BREAKPOINT

@media (max-width: 1024px) {

  .home-text{
    display: none;
    align-items: flex-start;
    padding-top: 140px;
  }

}