.home-text{
  position: absolute;
  top:0;
  left:0;
  background-color: $pink;
  width: 100%;
  height: 100%;
  padding: $margin;
  display: flex;
  align-items: flex-end;
  z-index: 9999;
}

.close-cross {
  position: absolute;
  top: $margin - 1px;
  right: $margin - 1px;
  cursor: pointer;
  &:before {
    content: "\E001";
    font-size: 72px;
    line-height: 72px;
    color: $red;
  }
}

.close-btn{
  cursor: pointer;
}

.home-text .logo{
  position: absolute;
  top: $margin - 5px;
  left: $margin -2px;
  &:before{
    content: "\E002";
    font-size: 77px;
    line-height: 77px;
    color: $red;
  }
}

.home-text-text-wrap{
  display: flex;
  align-items: flex-start;
  color: $red;
  p{
    text-indent: $margin;
    &:first-of-type{
      text-indent: 0px;
    }
  }
  .fr{
    padding-right: $margin/2;
    width: 50%;
  }
  .en{
    padding-left: $margin/2;
    width: 50%;
  }
}


.home-gallery{
  height: 100%;
  width: 100%;
  padding-top: $margin;
  white-space: nowrap;
  overflow: scroll;
  .gallery-item{
    &:first-of-type{
      margin-left: $margin;
    }
    &:last-of-type{
      margin-right: $margin;
    }
    margin: 0 10px;
    display: inline-block;
    flex-direction: column;
    justify-content: flex-start;
    vertical-align: top;
  }
  .landscape{
    img{
      width: auto;
      height: 400px;
    }

  }
  .portrait{
    img{
      width: auto;
      height: 550px;
    }
  }
  .square{
    img{
      width: auto;
      height: 450px;
    }
    height: 480px;
  }
  .gallery-item-legend{
    display: none;
    padding:3px 0;
    overflow: hidden;
    white-space: normal;
  }
}

.legend-wrap{
  display: flex;
  p{
    display: inline;
  }
}

.event-cartel{
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  bottom: $margin;
  left: $margin;
  padding: 20px $margin;
  background-color: white;
  width: 555px;
  .event-cartel-text{
    padding-bottom: 23px;
    width: 100%;
  }
  .close-btn{
    display: inline-block;
    top: auto;
    bottom: 17px;
    right: $margin;
    &:before{
      color: black !important;
      font-size: 55px;
      line-height: 55px;
    }
  }
}