// TOUCH DEVICES

@media (hover: none) {
  .artist-cover-image{
    display: none;
  }
}

// SM BREAKPOINT
@media (max-width: 767px) {

  /// RESET ///

  main{
    padding-top: $header-height-sm;
  }

  /// MENU ///

  header{
    z-index: 999;
    padding: 0 $margin-sm;
  }

  .navbar{
    height: $header-height-sm;
    justify-content: flex-start;
  }
  .navbar-brand{
    display: block;
  }

  nav .logo{
    display: none;
  }

  .nav-page-title{
    flex: 1;
    display: block;
  }

  .navbar-collapse{
    background-color: $pink;
    position: fixed;
    top: $header-height-sm;
    flex-direction: column;
    left: 0;
    width: 100%;
    height: calc(100% - 41px);
    transition: none;
    .navbar-collapse-wrap{
      padding: 10px $margin-sm $header-height $margin-sm;
      flex:1;
      width: 100%;
    }
  }

  .navbar-collapse.collapsing{
    display: flex !important;
    height: calc(100% - 41px);
    min-height: calc(100% - 41px);
    transition: none;
  }

  .collapse.show{
    display: flex !important;
  }

  .navbar-brand{
    padding-bottom: 0.02em !important;
    display: block;
  }

  .navbar-brand:hover{
    text-decoration: none;
    box-shadow: inset 0 -1px 0 #000 !important;
    padding-bottom: 0.02em !important;

  }

  .nav-link.menu-link{
    color: $red !important;
  }
  .nav-link{
    padding: 0;
    display: inline-block;
  }

  .nav-link.hover-td-undl:hover{
    box-shadow: inset 0 -1px 0 $red !important;
    padding-bottom: 0.02em !important;
  }

  .nav-link.td-undl{
    box-shadow: none !important;
    padding-bottom: 0.02em !important;
  }

  .navbar-nav{
    height: 100%;
    li{
      line-height: 23px;
    }
  }

  .lang-fr{
    &:before{
      content: "Français";
    }
  }

  .lang-en{
    &:before{
      content: "English";
    }
  }

  .mobile-menu{
    display: block;
    padding-top: 17px;
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;

    .socials{
      position: fixed;
      bottom: $margin-sm - 5px;
      width: 100%;
      left: 11px;
      flex:1;
      a{
        color: $red;
        font-size: 20px;
      }
    }

    .event-cartel{
      position: relative;
      top: auto;
      margin-bottom: 4px;
      padding: 12px 18px 17px 18px;
    }
  }

  .language-menu{
    position: fixed;
    z-index: 99999;
    bottom: $margin-sm - 3px;
    right: $margin-sm;
  }



  .mobile-menu .icon.logo{
    width: 100%;
    display: block;
    left:0;
    text-align: center;
    margin-bottom: 14px;
    &:before{
      font-size: 57px;
      content:"\E002" !important;
    }
  }

  /// HOME ///

  .home-text{
    display: none;
    align-items: flex-start;
    padding-top: 140px;
  }

  .home-text-text-wrap{
    flex-direction: column;
    width: 100%;
    height: 100%;
    .en,.fr{
      max-height: 50%;
      width: 100%;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
      p{
        width: 100%;
      }
      &:after{
        content:"Lire plus";
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .fr{
      padding-bottom: 12.5px;
      border-bottom: 1px solid;
    }
  }

  .event-cartel{
    position: relative;
    z-index: 9999999;
    width: 100%;
    left:auto;
    top: calc(100vh - 300px);
    bottom: auto;
    margin: 0 auto;
  }

  .content .event-cartel{
    display: none !important;
  }


  .landscape .more, .square .more {
    margin-top: 16px;
  }

  .home-gallery{
    white-space: auto;
    padding: $margin-sm;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-top: $margin-sm;
    .gallery-item{
      margin: 0 !important;
      display: block;
      width: 100% !important;
      max-width: 100% !important;
      height: auto;
      &:last-of-type{
        .gallery-item-legend{
          padding: 3px 0px 0px 0px;
        }
      }
      .gallery-item-legend{
        display: block !important;
        padding: 3px 0 14px 0;
        max-width: 100%;
        line-height: 0px;
      }
    }
    .landscape{
      width: 340px;
      max-width: 340px;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }

    .portrait{
      width: 265px;
      max-width: 265px;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }

    .square{
      width: 272.5px;
      max-width: 272.5px;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
    }
  }




  ///// MENU PAGE //////

  .submenu{
    padding: calc($margin / 2.5) $margin-sm 12px $margin-sm;
  }

  .submenu.tags-menu{
    padding:  0 $margin-sm calc($margin / 3.1) $margin-sm;
    .tag{
    margin-top: calc($margin / 3.1);
    }
  }

  .subpage-content{
    padding: 9px $margin-sm 14px;
    p{
      text-indent: 14px;
    }
    &.has-socials{
      padding-bottom: 14px;
    }
  }

  .subpage-content-header{
    width: 100%;
    p:first-of-type{
      text-indent: 0px;
    }
    p{
      text-indent: 14px;
    }
    padding-bottom: 3px;
  }

  .grid-item{
    width: 100%;
    border-right: 0;
    padding: $margin-sm $margin-sm $margin-sm - 4px $margin-sm;
    &:last-of-type{
      border-bottom: 0px;
    }
    &.landscape .disable{
      display: none;
    }
    .grid-item-content{
      padding-top:1px;
    }
  }

  .nb-b-sm{
    border-bottom: none;
  }

  .sm-fd-c{
    flex-direction: column;
  }

  .subpage-content-image{
    padding-top: 8px;
    img.portrait{
      width: 265px;
      height: auto;
    }
    img.landscape{
      width: 340px;
      height: auto;
    }
    .legend{
    }
  }

  .menu-page-socials{
    display: none;
  }

  .submenu a.ajax-link{
    margin-right: 12px;
  }

  .submenu .arrow a.ajax-link{
    margin-right: 0px;
  }

  .submenu .arrow{
    margin-right: 12px;
  }

  .filter{
    margin-right: 12px;
  }

  .subpage-content-header{
    ul{
      li{
        margin-left: 33px;
        position: relative;
        left: -4px;
        &:before {
          content: "—";
          position: relative;
          left: 4px;
          margin-right: 19px;
          margin-left: -33px;
        }
      }
      &:last-of-type{
        margin-bottom: 0px;
      }
    }
  }

  .publication-text{
    ul{
      li{
        margin-left: 33px;
        position: relative;
        left: -4px;
        &:before {
          content: "—";
          position: relative;
          left: 7px;
          margin-right: 19px;
          margin-left: -33px;
        }
      }
      &:last-of-type{
        margin-bottom: 0px;
      }
    }
  }

  .legend{
    padding-top: 3px;
  }

  ////// SUB SUBPAGE

  .publication-cover {
    padding-top: 8px;
    &.landscape {
      width: 340px;
      max-width: 340px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }

    &.portrait {
      width: 265px;
      max-width: 265px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }

    &.square {
      width: 272.5px;
      max-width: 272.5px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  // PUBLICATION

  .subpage-content.publication{
    .publication-infos{
      margin-top: 1.1em;
      margin-bottom: 5px;
    }
    .publication-text{
      margin-bottom: 0;
    }
  }

  .publication-text{
    margin-bottom: 5px;
  }

  .publication-images{
    .image.portrait{
      img{
      width: 265px;
      max-width: 265px;
      height: auto;
      }
    }

    .image.landscape{
      img{
        width: 340px;
        max-width: 340px;
        height: auto;
      }
    }

    .image.square{
      img{
        width: 272.5px;
        max-width: 272.5px;
        height: auto;
      }
    }
  }

  //// EVENT

  .event{
    .publication-images{
      padding-bottom: 0px;
    }
  }

  ////// ÉQUIPE

  .team-member {
    &:first-of-type{
      padding: 0px $margin-sm 13px;
    }
    padding: 9px $margin-sm 13px;
    img {
      height: auto;
      width: 265px;
    }
  }

  .team-member-wrap {
    margin-left: - $margin-sm;
    margin-right: - $margin-sm;
  }

  .team-member-position{
    margin-left: 30px;
  }

  ///// VIDEOS

  .video-wrap{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    iframe{
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
  }

  .video-item{
    &:first-of-type{
      margin: 9px 0 0 0;
    }
    margin: 15px 0 0 0;

    &:last-of-type{
      margin: 15px 0 0 0;
    }

  }


////GRID TEMPLATE

  .grid-item.portrait .more {
    right: 18px;
  }

  .grid-item.portrait img {
    height: auto;
    width: calc(100% - 66px);
    max-width: 265px;
  }


    .landscape .grid-item-content img{
  width: 100%;
  max-width: 340px;
  height: auto;
}

.square .grid-item-content img{
  width: 272.5px;
  max-width: 272.5px;
  height: auto;
}

  .icon-more a {
    width: 48px;
    height: 48px;
  }

  .more:before {
    font-size: 48px;
    line-height: 48px;
  }

    // PUBLICATION

.publication-text {
  p:last-of-type {
    margin-bottom: 0px;
  }
}

// NEWSLETTER

  .input{
    width: 100%;
  }

  .checkmark{
    top: 5px;
  }


  // PARTNER

  .toggle-expand{
    margin-right: 8px;
  }

  .partner{
    border-bottom: 1px solid;
    padding: 9px $margin 13px;
    &:first-of-type{
      padding: 0px $margin 13px;
    }
    &:last-of-type{
      border-bottom: 0px;
    }
    .legend{
      height: 23px;
    }
  }

  .partner-adress {
    margin-left: 29px;
  }

  .partner{
    .partner-image.landscape{
      img{
        width: 340px;
        height: auto;
      }
    }
    .partner-image.portrait{
      img{
        width: 265px;
        height: auto;
      }
    }
  }

  // ONGOING

  // EXHIBITION

  .exhibition .publication-cover{
    padding-top: 12px;
  }

  .exhibition{
    .publication-images{
      padding-bottom: 2px;
    }
  }

  .exhibition{
    .publication-images{
      padding-top: 1.1em;
    }
  }


// ARTISTES

  .header-artist .publication-cover-wrap {
    margin-right: 0px;
  }

  .ongoing.publication-text{
    padding-top: 18px !important;
  }

  .past.publication-text{
    margin-bottom: 0px;
  }

  .container-wrap.header-artist {
    display: block;
  }

  .publication-cover-wrap{
    margin-right: 18px;
  }

  .artist-content .publication-cover{
    padding-top: 12px;
  }

  .artist-content .publication-text{
    padding-top: 9px;
  }

.artist-cover-image{
  display: none !important;
}

  .artist-content .container-wrap {
    margin-left: -18px;
    margin-right: -18px;
    padding-right:  18px;
    padding-left: 18px;
    padding-bottom: 14px;
  }

  .artist-content .container-wrap:last-of-type{
    padding-bottom: 0px;
  }


    .artist-content{
    .image-wrap{
      margin-top: $margin-sm;
    }

    .video-wrap{
      margin-top: $margin-sm;
    }
  }

  .artist-image{
    padding-top: 14px !important;
    &:first-of-type{
      padding-top: 9px !important;
    }
    &:last-of-type{
      margin-bottom: 9px;
    }
  }



  .past .artist-list-item span+a{
    margin-left: -1px;
  }

  .past .artist-list-item a {
    margin-left: 23px;
  }

  .container-wrap.artist-gallery{
      .more{
        margin-top: 16px;
      }
  }

  .container-wrap.artist-gallery.landscape{
    flex-direction: column;
    padding-bottom: $margin-sm;
    .icon-more{
      align-self: flex-end;
    }
  }

  .image-wrap.square{
    img {
      width: 272.5px;
      max-width: 272.5px;
      height: auto;
    }
  }

  .image-wrap.portrait{
    img {
      width: 265px;
      max-width: 265px;
      height: auto;
    }
  }

  .image-wrap.landscape{
    img {
      width: 340px;
      max-width: 340px;
      height: auto;
    }
  }

  .subpage-content-image.portrait{
    img{
      width: 265px;
      max-width: 265px;
      height: auto;
    }
  }

  .subpage-content-image.square{
    img{
      width: 272.5px;
      max-width: 272.5px;
      height: auto;
    }
  }

  .subpage-content-image.landscape{
    img{
      width: 340px;
      max-width: 340px;
      height: auto;
    }
  }

  .portrait img {
    width: 265px;
    max-width: 265px;
    height: auto;
  }

  .landscape img {
    width: 340px;
    max-width: 340px;
    height: auto;
  }

  .square img{
    width: 272.5px;
    max-width: 272.5px;
    height: auto;
  }

  .past .artist-list-item{

    span{
      display: inline-block;
      width: 21px;
    }
    span+a{

    }
    a{
      margin-left: 25px;
    }
  }


  // RADIO PAGE

  .subpage-content-grid .subpage-content-header-radio{
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 9px;
    padding-bottom: 14px;
  }

  .radio-item {

    .radio-item-row{
      position: relative;
    }

    .play-pause{
      position: absolute;
      right: 0;
      bottom: 0px;
      border-left: 1px solid;
      border-top: 1px solid;
      padding-bottom: 9px;
      padding-top: 6px;
      padding-left: 18px;
      padding-right: 18px;
      display: flex;
      justify-content: center;
      min-width: 96px;
      width: 96px;

    }

    button{
      padding-bottom: 0.02em;
      text-align: center;
    }

    .radio-item-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 0;
      padding-left: 18px;
      padding-right: 18px;

      .radio-item-title {
        padding-bottom: 5px;
        width: calc( 100% - 30px);
        flex: auto;

      }

      .radio-item-date {
        height: 44px;
        display: flex;
        align-items: center;
        order: 2;
      }

      .time{
        order: 3;
        display: flex;
        align-items: center;
      }

    }

    .radio-listener{
      padding: 0 18px;
    }

  }

  .radio-item .audio-player .timeline{
    width: 100%;
  }

  .radio-item .radio-collapse-content{
    padding: 9px 18px 14px 18px;
  }

}
